<template>
  <div class="grid-x">
    <div class="cell small-12 medium-8 bg-grey pb-5">
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 medium-10 medium-offset-1 mb">
          <div class="headline-box">
            <h1 class="text-center text-white">
              Budget<br />
              Impact<br />
              Model
            </h1>
            <h2 class="text-center text-white">Link Generator</h2>
          </div>
        </div>
      </div>
      <div class="grid-x grid-padding-x pt-2 pb-2">
        <div class="cell small-12 medium-10 medium-offset-1">
          <input
            placeholder="Username"
            name="email"
            type="text"
            v-model="email">
          <input
            placeholder="Password"
            name="password"
            label="Password"
            type="password"
            v-model="password">
          <button @click="tryLogin" class="button float-right">Login</button>
        </div>
      </div>
      <div class="grid-x grid-padding-x pt-2 pb-2">
        <div class="cell small-12 medium-10 medium-offset-1 white-border">
          <p class="text-white text-center text-bold pa-3 mb-0 text-small">
            Adverse events should be reported.<br />
            UK: Reporting forms and information can be found at https://yellowcard.mhra.gov.uk/.<br />
            Adverse events should also be reported to Biogen Idec Limited.<br />
            Tel: +44(0)20 360 886 22. Email: medinfo.europe@biogen.com
          </p>
        </div>
      </div>
      <div class="grid-x grid-padding-x pt-4 pb-5">
        <div class="cell small-12 medium-5 medium-offset-1">
          <p class="text-white">
            Prescribing information can be found <a class="text-white text-underlined" target="_blank" href="https://biib.mobi/uk-byooviz-pi">here</a>.
          </p>
        </div>
        <div class="cell small-12 medium-5">
          <p class="text-white text-right">
            Biogen-192046 Date of preparation: February 2023
          </p>
        </div>
      </div>
    </div>
    <div class="cell small-12 medium-4">
      <div :style="{ height: viewportHeight + 'px' }" class="relative">
        <img class="top-logo" src="../assets/logos/biogen_logo.svg" alt="" />
        <img class="bottom-logo" src="../assets/logos/Byooviz_logo.png" alt="" />
      </div>
    </div>
    <app-message
      v-if="showMessage === true"
      :messageText="message"
      :link="false"
      :linkText="false"
      @closeMessage="showMessage = false" />
  </div>
</template>

<script>
import Message from '@/components/Messages/Message.vue';
import axios from '../axios';

export default {
  name: 'Login',
  components: {
    appMessage: Message,
  },
  computed: {
    userid() {
      return this.$store.state.userid;
    },
  },
  watch: {
    userid() {
      this.testAuth();
    },
  },
  data() {
    return {
      email: '',
      failed: false,
      hide: true,
      message: '',
      password: '',
      showMessage: false,
      viewportHeight: 0,
    };
  },
  methods: {
    testAuth() {
      if (this.userid !== '') {
        console.log('var');
        this.$router.push('/links');
      } else {
        this.hide = false;
      }
    },
    tryLogin() {
      const postData = {};
      postData.email = this.email;
      postData.password = this.password;
      axios.post('/users/login.json', postData)
        .then((response) => {
          this.$store.dispatch('setCredentials', response.data);
          this.$router.push('/links');
        })
        .catch(() => {
          this.message = 'Sorry, the email or password was incorrect.';
          this.showMessage = true;
        });
    },
  },
  mounted() {
    this.testAuth();
    this.viewportHeight = window.innerHeight;
  },
};
</script>
